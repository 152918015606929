import { LINKS } from '@utils/constants';
import { Link } from 'gatsby';
import React from 'react';

import Hero from '@components/Hero/Hero';
import Columns from '@components/Layout/Columns/Columns';
import Content from '@components/Layout/Content/Content';
import PageSection from '@components/Layout/PageSection/PageSection';
import CategoryListing from '@components/Listing/CategoryListing';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Button from '@components/Molecule/Button/Button';
import ProductSearch from '@components/Search/ProductSearch';
import Copy, { MotionCopy } from '@components/Typography/Copy';
import { MotionH } from '@components/Typography/H';

const NotFoundPage = () => (
	<Page transparent={true} background="black">
		<SEO title="404: Not found | UHV Design" />

		<Hero>
			<Content>
				<MotionH level={4} as="h1">
					404 - Page not found
				</MotionH>
				<MotionCopy font="xl3" weight="bold">
					...just the empty void of nothingness
				</MotionCopy>

				<MotionCopy weight="bold">
					Search our products or try select a category below to find
					what you are looking for.
				</MotionCopy>
			</Content>
		</Hero>

		<PageSection>
			<Columns gap="lg">
				<ProductSearch type="alternate" />

				<div className="row">
					<MotionCopy weight="bold">Or: </MotionCopy>

					<Button to="/" size="lg" align="center">
						Go to our homepage
					</Button>
				</div>
			</Columns>

			<CategoryListing />
		</PageSection>

		<PageSection background="blue">
			<Copy weight="bold" font="xl2" align="center" prose={true}>
				If you would like to discuss your project with our engineering
				team, please{' '}
				<Link to={LINKS.CONTACT} className="underline">
					contact us
				</Link>
				.
			</Copy>
		</PageSection>
	</Page>
);

export default NotFoundPage;
